
import { IonPage, IonGrid, IonRow, IonCol, IonInput } from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "../store";

export default defineComponent({
  name: "Signin",
  components: {
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
  },
  data: () => {
    return {
      store: useStore(),
      privacyChecked: false,
    };
  },
  computed: {
    formFields(): {} {
      return this.store.state.registerForm;
    },
  },
  methods: {
    calendarFocus(e: any) {
      e.target.type = "date";
    },
    loginFacebook() {
      if (this.store !== null) {
        this.store.dispatch("loginFacebook").then((res) => {
          if (res === "login") {
            this.$router.push({ path: "/" });
          }
        });
      } else {
        alert("no store found");
      }
    },
    loginGoogle() {
      if (this.store !== null) {
        this.store.dispatch("loginGoogle").then((res) => {
          if (res === "login") {
            this.$router.push({ path: "/" });
          }
        });
      } else {
        alert("no store found");
      }
    },
    createAccount() {
      if (this.store !== null) {
        this.store.dispatch("createAccount").then((res) => {
          // Redirect if logged in
          if (res === "login") {
            this.$router.push({ path: "/" });
          }
          // Display errors
          if (res.errors) {
            Object.keys(res.errors).map((errorType) => {
              alert(res.errors[errorType][0]);
            });
          }
        });
      } else {
        alert("no store found");
      }
    },
  },
});
